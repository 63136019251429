// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-js": () => import("./../../src/components/Post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("./../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-experience-js": () => import("./../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-uses-js": () => import("./../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

